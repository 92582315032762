<template>
  <img class="header-logo" src="../assets/biff-logo.jpg">
  <div v-if="showMenuButtons" class="tabs-container">
    <h2 @click="onTabClicked('leaderboard')" class="tab-items">Leaderboard</h2>
    <h2 @click="onTabClicked('schedule')" class="tab-items">Schedule</h2>
    <h2 @click="onTabClicked('enter-score')" class="tab-items">Enter Your Score</h2>
  </div>
  <div v-if="showCreateTeamsButton" class="tabs-container">
    <h2 @click="onCreateTeamsClicked()" class="tab-items">Create Teams</h2>
  </div>
</template>

<script>
import BiffService from "@/services/biffService";
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      showCreateTeamsButton: false,
      showMenuButtons: false
    }
  },
  components: {
    HelloWorld
  },
  methods: {
    async haveTeamsBeenCreated() {
      const leaderboard = await BiffService.getLeaderBoard();
      return leaderboard.length > 2;
    },
    async setData() {
      if (await this.haveTeamsBeenCreated()) this.showMenuButtons = true;
      else this.showCreateTeamsButton = true;
    },
    onTabClicked(name) {
      if (name === 'leaderboard') this.$router.push('/leaderboard');
      if (name === 'schedule') this.$router.push('/schedule');
      if (name === 'enter-score') this.$router.push('/enter-score');
    },
   async onCreateTeamsClicked() {
      await BiffService.createTeams();
     this.showMenuButtons = true;
     this.showCreateTeamsButton = false;
    }
  },
  async beforeMount() {
    await this.setData();
  }
}
</script>

<style>
.tabs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-items {
  width: 70%;
  color: black;
  padding: 5px;
  box-shadow: 0 0 10px 5px white;
  border-radius: 10px 10px 10px 10px;
  background-color: darkgrey;
  font-weight: bold;
  margin-top: 5rem;
}

.header-logo {
  width: 60%;
  height: auto;
}
</style>

<template>
  <div class="d-flex flex-column align-items-center">
    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 vue-container safari-toolbars-hidden">
      <router-view />
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.vue-container {
  background-size: cover;
  background-image: url("assets/mozam-house-bg.jpg");
  position: -webkit-sticky; /* Safari & IE */
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  min-height: 100vh;
}

</style>

import axios from "axios";
export default class BiffService {
    static async getLeaderBoard() {
        const response = await axios.post(`https://utils.vodacomfantasy.com/utils/get-leaderboard`);
        return response.data
    }

    static async createTeams() {
         await axios.post(`https://utils.vodacomfantasy.com/utils/create-teams`);
    }

    static async getBeerPongMatches() {
       const response = await axios.post(`https://utils.vodacomfantasy.com/utils/get-beerpong-matches`);
       return response.data;
    }

    static async addBeerPongResult(teamName, matchId) {
        const response = await axios.post(`https://utils.vodacomfantasy.com/utils/add-beerpong-result`, {
            teamName,
            matchId
        });
        return response.data;
    }

    static async addCricketOrGolfScore(eventName, teamName, points) {
        const response = await axios.post(`https://utils.vodacomfantasy.com/utils/add-cricket-golf-score`, {
            eventName,
            teamName,
            points
        });
        return response.data;
    }

    static async getEvent(eventName) {
        const response = await axios.post(`https://utils.vodacomfantasy.com/utils/get-event`, {
            eventName
        });
        return response.data;
    }
}

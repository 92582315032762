<template>
  <img class="header-logo" src="../assets/biff-logo.jpg">
  <div class="leaderboard-container">
    <table class="table-main">
      <th class="table-header">#</th>
      <th class="table-header">Team</th>
      <th class="table-header">Points</th>
      <tr v-for="team in leaderBoardArray">
        <td class="table-data">{{ team.Position }}</td>
        <td class="table-data">{{ team.TeamName }}</td>
        <td class="table-data">{{ team.Points }}</td>
      </tr>
    </table>
    <div @click="onBackClicked()" class="button">Back</div>
  </div>
</template>

<script>
import BiffService from "@/services/biffService";
export default {
  name: "LeaderboardView",
  data() {
    return {
      leaderBoardArray: []
    }
  },
  methods: {
    onBackClicked() {
      this.$router.push('/')
    }
  },
  async beforeMount() {
    this.leaderBoardArray = await BiffService.getLeaderBoard();
  }
}
</script>

<style scoped>
.leaderboard-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.table-main {
  width: 80%;
  border: solid black 1px;
  background-color: white;
}

.table-header {
  font-size: 1rem;
  border: solid black 1px;
  padding: 5px;
}

.table-data {
  font-size: 1rem;
  border: solid black 1px;
  padding: 5px;
}

.button {
  margin-top: 3rem;
  border-radius: 10px 10px 10px 10px;
  width: 50%;
  color: black;
  background-color: darkgrey;
  box-shadow: 0 0 10px 5px white;
  font-size: x-large;
  margin-bottom: 20px;
}
</style>

<template>
  <img class="header-logo" src="../assets/biff-logo.jpg">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="schedule-container">
      <h4><u>Friday</u></h4>
      <p><b>3am:</b> Meet at Engen Mt Edgcombe.</p>
      <p><b>9am:</b> Arrive at border post.</p>
      <p><b>10am:</b> Pick up a cold 2M for the road and head to the house.</p>
      <p><b>11am:</b> Arrvive unpack, settle in and wet the throat maybe line the stomachs with a chow.</p>
      <p><b>4pm:</b> Bruce braais for the lads.</p>
      <p><b>Rest of night:</b> Beer pong competition begins.</p>
    </div>
    <div class="schedule-container">
      <h4><u>Saturday</u></h4>
      <p><b>8am:</b> Wake up get some chow and pull yourself towards yourself.</p>
      <p><b>9am:</b> Pack and get ready head down to the beach.</p>
      <p><b>10am:</b> Beach cricket comp begins.</p>
      <p><b>2pm:</b> Arrive at Jack's. Start of the closest to pin comp.</p>
      <p><b>6pm:</b> Dinner at the Drunken Clam</p>
    </div>
    <div class="schedule-container">
      <h4><u>Sunday</u></h4>
      <p><b>10am:</b> Wake up, chow and get ready for the pub crawl.</p>
      <p><b>11am:</b> Pub crawl begins.</p>
      <p><b>1pm:</b> Final of the BIFF Cup is a pool competion at the slip and slide place.</p>
      <p><b>Late:</b> Get home and finish up any thing we have left to drink.</p>
    </div>
    <div class="schedule-container">
      <h4><u>Monday</u></h4>
      <p><b>Morning:</b> Wake up get ready, say cheers and get home to our normal lives again.</p>
      <p><b>Afternoon:</b> Drop Bruce off at the hospital in Stanger.</p>
    </div>
    <div @click="onBackClicked()" class="button">Back</div>
  </div>
</template>

<script>
export default {
  name: "ScheduleView",
  methods: {
    onBackClicked() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.schedule-container {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  /*align-items: center;*/
  margin-top: 2rem;
  width: 90%;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 0 10px 5px darkgrey;
}

.table-main {
  width: 90%;
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 0 10px 5px darkgrey;
}

.button {
  margin-top: 3rem;
  margin-bottom: 20px;
  border-radius: 10px 10px 10px 10px;
  width: 50%;
  color: black;
  background-color: darkgrey;
  box-shadow: 0 0 10px 5px white;
  font-size: x-large;
}
td {
  padding: 10px;
  font-size: large;
}

th {
  padding-top: 10px;
}
</style>

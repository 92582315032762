<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <div class="event-container">
      <h4>Beer Pong</h4>
      <p class="px-2">Please select the team that won your match by clicking on the winning team. Wining team's will be
        highlighted
        green.</p>
      <div v-for="match in beerPongMatches" class="beer-pong-box">
        <div @click="beerPongWinnerSelected(match.Team1Name, match.MatchId)" :class="getBeerPongTeamClass(match.Team1Name, match.Winner)">{{ match.Team1Name }}</div>
        <b>vs</b>
        <div @click="beerPongWinnerSelected(match.Team2Name, match.MatchId)" :class="getBeerPongTeamClass(match.Team2Name, match.Winner)">{{ match.Team2Name }}</div>
      </div>
    </div>
    <div class="event-container">
      <h4>Beach Cricket</h4>
      <p class="px-2">Please enter your total runs scored next to your team name.</p>
      <div v-for="team in cricketScores" class="beer-pong-box">
        <div class="beer-pong-team no-result-yet">{{ team.TeamName }}</div>
        <input class="beer-pong-team no-result-yet text-center" :placeholder="team.Points" v-model="team.Points"/>
        <button @click="onSubmitClicked('BeachCricket', team.TeamName, team.Points)" class="submit-button">
          Submit
        </button>
      </div>
    </div>
    <div class="event-container">
      <h4>Jack's Golf</h4>
      <p class="px-2">Please enter your total balls landed on the green.</p>
      <div v-for="team in golfScores" class="beer-pong-box">
        <div class="beer-pong-team no-result-yet">{{ team.TeamName }}</div>
        <input class="beer-pong-team no-result-yet text-center" :placeholder="team.Points" v-model="team.Points"/>
        <button @click="onSubmitClicked('Golf', team.TeamName, team.Points)" class="submit-button">
          Submit
        </button>
      </div>
    </div>
    <div @click="onBackClicked()" class="button">Back</div>
  </div>
</template>

<script>
import BiffService from "@/services/biffService";
import axios from "axios";

export default {
  name: "EnterScore",
  data() {
    return {
      beerPongMatches: [],
      cricketScores: [],
      golfScores: [],
    }
  },
  methods: {
    async onSubmitClicked(eventName, teamName, points) {
      await BiffService.addCricketOrGolfScore(eventName, teamName, points);
      this.cricketScores = await BiffService.getEvent('BeachCricket');
    },
    async beerPongWinnerSelected(teamName, matchId) {
      await BiffService.addBeerPongResult(teamName, matchId);
      this.beerPongMatches = await BiffService.getBeerPongMatches();
    },
    onBackClicked() {
      this.$router.push('/')
    },
    getBeerPongTeamClass(teamName, winningTeam) {
      if (!winningTeam) return 'beer-pong-team no-result-yet'
      if (winningTeam === teamName) return 'beer-pong-team winner-selected'
      if (winningTeam !== teamName) return 'beer-pong-team loser-selected'
    }
  },
  async beforeMount() {
    this.beerPongMatches = await BiffService.getBeerPongMatches();
    this.cricketScores = await BiffService.getEvent('BeachCricket');
    this.golfScores = await BiffService.getEvent('Golf');
  }
}
</script>

<style scoped>
.event-container {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 90%;
  background-color: darkgrey;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 0 10px 5px white;
}

.beer-pong-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px;
}

.beer-pong-team {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 5px 5px 5px;
  padding: 2px;
}

.winner-selected {
  background-color: green;
}

.loser-selected {
  background-color: red;
}

.no-result-yet {
  background-color: white;
}

.button {
  margin-top: 20px;
  border-radius: 10px 10px 10px 10px;
  width: 50%;
  color: black;
  background-color: darkgrey;
  box-shadow: 0 0 10px 5px white;
  font-size: x-large;
  margin-bottom: 20px;
}
</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LeaderboardView from "@/views/LeaderboardView";
import ScheduleView from "@/views/ScheduleView";
import EnterScore from "@/views/EnterScore";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/leaderboard',
    name: 'LeaderboardView',
    component: LeaderboardView
  },
  {
    path: '/schedule',
    name: 'ScheduleView',
    component: ScheduleView
  },
  {
    path: '/enter-score',
    name: 'EnterScore',
    component: EnterScore
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
